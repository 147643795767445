import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

import { space } from 'styled-system';
import { FormattedMessage } from 'react-intl';
import { Flex, Box } from '@rebass/grid';
import CatOnWaves from '../components/CatOnWaves';
import Flowers from '../components/Flowers';

import withLayout from '../layout';

const TextWrapper = styled(Box)`
  max-width: 892px;

  * {
    text-align: left;
  }
  h4,
  p,
  li {
    line-height: 2;
  }
  h4 {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.grey};
    text-transform: none;
    margin: 20px 0;
    font-weight: bolder;
  }
  p {
    margin: 0;
  }

  ol {
    list-style-type: lower-alpha;
  }

  ul {
    padding-left: 0;
    list-style-position: inside;
    list-style-type: circle;
  }

  li {
    color: ${({ theme }) => theme.colors.darkGrey};
    font-family: 'Open Sans', sans-serif;
    font-size: 1.4rem;
    text-transform: none;
  }
`;

const PrivacyPolicy = () => {
  const renderChildElement = (child, index) => {
    return child.type === 'element'
      ? React.createElement(
          child.tagName,
          { key: index },
          child.children.map((grandChild, grandChildIndex) =>
            renderChildElement(grandChild, `${index}${grandChildIndex}`),
          ),
        )
      : child.value;
  };

  return (
    <StaticQuery
      query={privacyPolicyQuery}
      render={data => (
        <Flex flexDirection="column" alignItems="center" width={1}>
          <TextWrapper width={1} px={36} mt={40} mb={{ mobile: 0, laptop: 50 }}>
            <h1 css={space} mt={0} mb={50}>
              <FormattedMessage id="nav.privacyPolicy" />
            </h1>
            {data.markdownRemark.htmlAst.children.map((child, index) =>
              renderChildElement(child, index),
            )}
          </TextWrapper>
          <Box
            css={`
              position: relative;
            `}
          >
            <CatOnWaves />
            <Flowers.BottomWaves />
          </Box>
        </Flex>
      )}
    />
  );
};

const privacyPolicyQuery = graphql`
  query {
    markdownRemark {
      htmlAst
    }
  }
`;

const customProps = {
  localeKey: 'privacyPolicy',
};

export default withLayout(customProps)(PrivacyPolicy);
